import {
  Button,
  Row,
  Col,
  Container,
  Alert,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { useState, useEffect } from "react";
import { createTransaction } from "../services/subscriptionServices";
import { register, checkStore } from "../services/registerServices";
import { useNavigate } from "react-router-dom";
export default function RegisterFormComp() {
  const [snapToken, setSnapToken] = useState("");
  const delay = 1;
  let timer1;
  const navigate = useNavigate();

  const [data, setData] = useState({
    store_name: "",
    name: "",
    email: "",
    whatsapp: "",
    password: "",
    conf_password: "",
    role: "Customer",
    affiliate: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [disabbleButton, setDisabbleButton] = useState(true);
  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  const [hasSubmit, setHasSubmit] = useState(false);

  useEffect(() => {
    if (
      data.store_name != "" &&
      data.email != "" &&
      data.whatsapp != "" &&
      data.whatsapp.length > 8 &&
      data.password.length > 5
    ) {
      if (data.password == data.conf_password) {
        setDisabbleButton(false);
      } else {
        setDisabbleButton(true);
      }
    } else {
      setDisabbleButton(true);
    }
  }, [data]);
  const registerNow = () => {
    setHasSubmit(false);
    // console.log("data", data)
    if (data.password === data.conf_password) {
      register(data)
        .then((result) => {
          import("react-facebook-pixel")
            .then((x) => x.default)
            .then((ReactPixel) => {
              ReactPixel.init("1610568986009768");
              ReactPixel.track("Purchase");
            });
          setVariant("success");
          setMessage("Registrasi berhasil");
          setHasSubmit(true);
          let payload = {
            app_id: result.data.app_id,
            nominal: 50000,
            email: data.email,
            name: data.name,
            phone: data.whatsapp,
            affiliate: data.affiliate,
          };
          createTransaction(payload)
            .then((res) => {
              if (res.status === 201) {
                setSnapToken(res.data.token);

                window.snap.pay(res.data.token, {
                  onSuccess: function (result) {
                    /* You may add your own implementation here */
                    alert("payment success!");
                    console.log(result);
                  },
                  onPending: function (result) {
                    /* You may add your own implementation here */
                    //   alert("wating your payment!"); console.log(result);
                    navigate(
                      `/payment/pending?order_id=${result.order_id}&pdf_url=${result.pdf_url}`
                    );
                  },
                  onError: function (result) {
                    /* You may add your own implementation here */
                    alert("payment failed!");
                    console.log(result);
                  },
                  onClose: function () {
                    /* You may add your own implementation here */
                    alert("you closed the popup without finishing the payment");
                  },
                });
              }
            })
            .catch((err) => {
              console.log("err", err);
            });
        })
        .catch((err) => {
          if (message != "Registrasi berhasil") {
            setMessage("Registrasi gagal nama usaha sudah diambil");
            setVariant("danger");
          }
          setHasSubmit(true);
        });
    }
  };

  // const midtrans = () => {
  //     // SnapToken acquired from previous step
  //     snap.pay('PUT_TRANSACTION_TOKEN_HERE', {
  //         // Optional
  //         onSuccess: function(result){
  //           /* You may add your own js here, this is just example */ document.getElementById('result-json').innerHTML += JSON.stringify(result, null, 2);
  //         },
  //         // Optional
  //         onPending: function(result){
  //           /* You may add your own js here, this is just example */ document.getElementById('result-json').innerHTML += JSON.stringify(result, null, 2);
  //         },
  //         // Optional
  //         onError: function(result){
  //           /* You may add your own js here, this is just example */ document.getElementById('result-json').innerHTML += JSON.stringify(result, null, 2);
  //         }
  //       });
  // }

  const chageConfirmationPassword = (conf_password) => {
    setData((prev) => {
      return {
        ...prev,
        conf_password,
      };
    });
  };

  const changePassword = (password) => [
    setData((prev) => {
      return {
        ...prev,
        password,
      };
    }),
  ];
  const changeEmail = (email) => {
    setData((prev) => {
      return {
        ...prev,
        email,
      };
    });
  };
  const changeWhatsapp = (whatsapp) => {
    setData((prev) => {
      return {
        ...prev,
        whatsapp,
      };
    });
  };

  const changeAffiliate = (affiliate) => {
    setData((prev) => {
      return {
        ...prev,
        affiliate,
      };
    });
  };

  const changeRetailname = (store_name) => {
    setData((prev) => {
      return {
        ...prev,
        store_name: store_name.toLowerCase(),
        name: store_name.toLowerCase(),
      };
    });
  };

  const changeRefCode = (reff_code) => {
    setData((prev) => {
      return {
        ...prev,
        reff_code: reff_code,
      };
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      let result = await checkStore(data.store_name);
      console.log("result", result.data);
      if (result.data.status == "not available") {
        setHasSubmit(true);
        setVariant("danger");
        setMessage("Nama usaha telah digunakan, coba lagi");
      } else {
        setHasSubmit(false);
        setVariant("");
        setMessage("");
      }
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, [data.store_name]);

  return (
    <div>
      <div className="card-body ">
        <h4>Register</h4>
        <p>Isi data untuk pendaftaran</p>
        {hasSubmit && <Alert variant={variant}>{message}</Alert>}
        <div className="form-row">
          <InputGroup className="mb-2">
            <FormControl
              placeholder="nama usaha"
              aria-label="nama usaha"
              aria-describedby="basic-addon2"
              onChange={($e) => changeRetailname($e.target.value)}
              value={data.store_name}
            />
            {/* <InputGroup.Text id="basic-addon2">.idinvitebook.online</InputGroup.Text> */}
          </InputGroup>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4" className="mb-2 mt-2">
              Email
            </label>
            <input
              value={data.email}
              type="email"
              className="form-control"
              id="inputEmail4"
              onChange={($e) => changeEmail($e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4" className="mb-2 mt-2">
              Nomer WA
            </label>
            <input
              value={data.whatsapp}
              type="text"
              className="form-control"
              id="inputEmail4"
              onChange={($e) => changeWhatsapp($e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4" className="mb-2 mt-2">
              Kata sandi (minimal 6 karakter)
            </label>
            <input
              value={data.password}
              type="password"
              className="form-control"
              onChange={($e) => changePassword($e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4" className="mb-2 mt-2">
              Konfirmasi kata sandi
            </label>
            <input
              type="password"
              value={data.conf_password}
              className="form-control"
              onChange={($e) => chageConfirmationPassword($e.target.value)}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group col-md-12">
            <label htmlFor="inputEmail4" className="mb-2 mt-2">
              Kode Referal
            </label>
            <input
              value={data.affiliate}
              type="text"
              className="form-control"
              id="inputEmail4"
              onChange={($e) => changeAffiliate($e.target.value)}
            />
          </div>
        </div>
        {/* <div className="form-row">
                    <div className="form-group col-md-12">
                        <label htmlFor="inputEmail4" className="mb-2 mt-2">Kode Referal</label>
                        <input
                            type="password"
                            value={data.reff_code}
                            className="form-control"
                            onChange={($e) => changeRefCode($e.target.value)}
                        />
                    </div>
                </div> */}
        <Button
          className="btn btn-primary mt-4 btn-full"
          onClick={() => registerNow()}
          disabled={disabbleButton}
        >
          Daftarkan Akun
        </Button>
        <div className="mt-2">
          Sudah punya akun?{" "}
          <a href="https://app.idinvitebook.com/login">Login</a>
        </div>
      </div>
    </div>
  );
}
